import React from "react"

import Vision, { VisionProps } from "@src/scenes"
import store from "@src/services/redux/store"
import { Provider } from "react-redux"

type VisionRootProps = {
  logindata: VisionProps["logindata"]
  companyInfoOverride?: VisionProps["companyInfo"]
  observeRouteChanges?: VisionProps["observeRouteChanges"]
  awsRum?: VisionProps["awsRum"]
}

export const VisionRoot = ({
  logindata,
  companyInfoOverride,
  observeRouteChanges = false,
  awsRum = null,
}: VisionRootProps) => {
  return (
    <Provider store={store}>
      <Vision
        logindata={logindata}
        companyInfoOverride={companyInfoOverride}
        observeRouteChanges={observeRouteChanges}
        awsRum={awsRum}
      />
    </Provider>
  )
}
