declare const REACT_ROUTER_BASE_URL: string

import React from "react"
import styled from "styled-components"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { datadogRum } from "@datadog/browser-rum"
import { AwsRum } from "aws-rum-web"

import VisionContainer from "./VisionContainer"
import Icon from "../components/Icon"
import ToastsManager from "@src/components/ToastsManager"
import ManagerDashboard from "@src/scenes/ManagerDashboard"
import ScrollToTop from "@src/components/ScrollToTop"
import { setWhoAmI } from "@src/services/actions"
import {
  fetchLatestScoringDatetime,
  getActiveActions,
  getCompanyInfo,
} from "@src/services/redux/reputations/actions"
import {
  selectCompanyInfo,
  selectLatestScoringDatetime,
} from "@src/services/redux/reputations/selectors"
import { connect } from "react-redux"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import { getGlobalConfig } from "@src/globalConfig"
import { RouteWatcher } from "./RouteWatcher"

export type VisionProps = {
  logindata: {
    is_admin: boolean
    is_manager: boolean
    id: string
    full_name: string
    email: string
  }
  setWhoAmI: (logindata: VisionProps["logindata"]) => void
  getActiveActions: () => Promise<void>
  getCompanyInfo: () => Promise<void>
  fetchLatestScoringDatetime: () => Promise<void>
  companyInfo?: {
    id: string
    shortname: string
    default_timezone: string
    services: any
    plan: string
  }
  observeRouteChanges: boolean
  latestScoringDatetime: string | null
  awsRum?: AwsRum | null
}

// ui-elevateplatform requires this ID to be set for some styling; it's horrible
// but we leave it for backwards compatiblity
const VisionIdWrapper = styled.div.attrs({ id: "vision-dashboard" })`
  background-color: rgb(250, 250, 250);

  // Here we force some mimecast style overrides to be applied to all of vision.
  // Eventually they will be applied to the whole app, but this lets us do it
  // incrementally for now.
  a {
    color: ${THEME_PRIMARY};
    :hover,
    :focus {
      color: ${THEME_HOVER};
    }
  }

  // Copied from ui-ep, this is a tricky one to get rid of
  a[class^="ButtonLink__"] {
    color: ${THEME_PRIMARY} !important;
    :hover {
      text-decoration: underline !important;
      color: ${THEME_HOVER} !important;
    }
  }
`

class Vision extends React.Component<VisionProps> {
  state = {
    hasError: false,
    isBootstrapping: true,
  }

  componentDidMount() {
    const {
      setWhoAmI,
      logindata,
      getActiveActions,
      getCompanyInfo,
      companyInfo,
      fetchLatestScoringDatetime,
    } = this.props

    // All of these must succeed before we can load any part of the app.
    Promise.all([
      getActiveActions(),
      !companyInfo ? getCompanyInfo() : Promise.resolve(),
      fetchLatestScoringDatetime().catch((err) => {
        // Only require the latest scoring datetime there is a provisioning
        // message to show. Otherwise we can ignore the failure and show the
        // rest of the app, in case it's just the latest scoring datetime
        // endpoint that's failing.
        if (getGlobalConfig("PROVISIONING_SCREEN_MESSAGE")) {
          throw err
        }
      }),
    ])
      .catch(() => {
        this.setState({ hasError: true })
      })
      .finally(() => {
        this.setState({ isBootstrapping: false })
      })

    if (logindata) {
      setWhoAmI(logindata)
    }
  }

  resetErrorState = () => {
    if (this.state.hasError) this.setState({ hasError: false })
  }

  componentDidCatch(error: Error, info: any) {
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError: any = new Error(error.message)
    renderingError.name = "ReactRenderingError"
    renderingError.stack = info.componentStack
    renderingError.cause = error

    datadogRum.addError(renderingError)

    this.setState({ hasError: true })
  }

  render() {
    const { hasError, isBootstrapping } = this.state
    const css = `
      .center {
        margin: auto;
        padding: 10px;
        width: 50%;
      }
      .center-text {
        display: table;
        margin: 0 auto;
      }
      .error-header-text{
        font-family: inherit;
        color: #1D1E1F;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 5px;
      }
      .error-text {
        font-family: inherit;
        color: #565D66;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
      }
      .error-large-width {
        width: 425px;
      }
      .error-text-top {
        margin-top: 15px;
      }
    `

    return (
      <>
        {hasError ? (
          <VisionIdWrapper>
            <style>{css}</style>
            <div className="error-width">
              <div className="center error-large-width">
                {getGlobalConfig("ELLIE") && <Icon name="error" />}
                <div className="error-large-width error-text-top">
                  <div className="center-text error-header-text">
                    Oh no, something&apos;s gone awry...
                  </div>
                  <div className="center-text error-text">
                    Sorry about that! Our team has been notified of the issue.
                    In the meantime, please try refreshing the page or trying
                    again in a few minutes.
                  </div>
                </div>
              </div>
            </div>
          </VisionIdWrapper>
        ) : isBootstrapping ? (
          <ActivityIndicator active />
        ) : getGlobalConfig("PROVISIONING_SCREEN_MESSAGE") &&
          !this.props.latestScoringDatetime ? (
          <div
            style={{
              height: "calc(100vh - 200px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "1.5em",
            }}
          >
            {getGlobalConfig("PROVISIONING_SCREEN_MESSAGE")}
          </div>
        ) : (
          <Router basename={REACT_ROUTER_BASE_URL}>
            <RouteWatcher
              pollForChanges={this.props.observeRouteChanges}
              awsRum={this.props.awsRum}
            />
            <ToastsManager fixed />
            <Switch>
              <Route
                path="/vision2/manager-dashboard/:person_nid?"
                component={ManagerDashboard}
              />
              <Route path="/vision2/:profileId/profile">
                <VisionIdWrapper>
                  <VisionContainer resetError={this.resetErrorState} />
                </VisionIdWrapper>
              </Route>
              <Route path="">
                <VisionIdWrapper>
                  <ScrollToTop />
                  <VisionContainer resetError={this.resetErrorState} />
                </VisionIdWrapper>
              </Route>
            </Switch>
          </Router>
        )}
      </>
    )
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    companyInfo: selectCompanyInfo(state) || ownProps.companyInfoOverride,
    latestScoringDatetime: selectLatestScoringDatetime(state),
  }
}

const mapDispatchToProps = {
  setWhoAmI,
  getActiveActions,
  getCompanyInfo,
  fetchLatestScoringDatetime,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vision)
